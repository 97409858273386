import { isMobile } from 'detect-touch-device';

function registerHoverEffects() {
  console.log('addingEffects')
  $('.mymultiplediv').mouseover(function () {
    const myvar = this.id;
    $("div.mydiv").hide();
    $(`#div${myvar}`).fadeIn('fast');
  });

  $('.mymultiplediv').mouseout(function () {
    const myvar = this.id;
    $("div.mydiv").hide();
    $(`#div${myvar}`).fadeOut('fast');
  });
}

function init() {
  if (!isMobile) {
    registerHoverEffects()
  }
}

$(document).ready(function () {

  init()


})


